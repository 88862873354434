import { Form, Formik } from 'formik';
import { Link } from 'gatsby';
import React, { VFC, useState } from 'react';
import * as Yup from 'yup';

import { ActivityIndicator } from '@stur/components/core/activity-indicator';
import { Button, LinkButton } from '@stur/components/core/button';
import { FormError } from '@stur/components/core/form-error';
import { FormField } from '@stur/components/core/form-field';
import { Icon } from '@stur/components/core/icon';
import { PasswordToggleInput } from '@stur/components/core/password-toggle-input';
import { AuthProvider } from '@stur/models/auth-provider-model';
import { RoutingUtils } from '@stur/utils/routing-utils';

import styles from './login-form.module.scss';

export interface LoginFormProps {
  isLoading: boolean;
  isCreate?: boolean;
  providers?: AuthProvider[];
  onSubmit: (formValue: LoginFormValue) => void;
  onFacebookAuth: () => void;
  onGoogleAuth: () => void;
}

export interface LoginFormValue {
  email: string;
  password: string;
  confirmPassword: string;
}

const initialValues: LoginFormValue = {
  email: '',
  password: '',
  confirmPassword: '',
};

const createValidationSchema: Yup.SchemaOf<LoginFormValue> = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const loginValidationSchema: Yup.SchemaOf<LoginFormValue> = createValidationSchema.omit([
  'confirmPassword',
]);

export const LoginForm: VFC<LoginFormProps> = (props) => {
  const { isCreate, isLoading, providers, onSubmit, onFacebookAuth, onGoogleAuth } = props;
  const [lastProvider, setLastProvider] = useState<AuthProvider | null>(null);
  const [revealPassword, setRevealPassword] = useState(false);
  const showEmail = !providers || providers.includes('email');
  const showFacebook = !providers || providers.includes('facebook');
  const showGoogle = !providers || providers.includes('google');

  let validationSchema: Yup.AnySchema | undefined;

  if (showEmail) {
    validationSchema = isCreate ? createValidationSchema : loginValidationSchema;
  }

  const handleSubmit = (values: LoginFormValue): void => {
    setLastProvider('email');
    onSubmit(values);
  };

  const handleFacebookClick = () => {
    setLastProvider('facebook');
    onFacebookAuth();
  };

  const handleGoogleClick = () => {
    setLastProvider('google');
    onGoogleAuth();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="login-form" noValidate>
        {showEmail && (
          <>
            <label>
              <span>Email Address</span>
              <FormField<LoginFormValue>
                type="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                disabled={isLoading}
              />
            </label>
            <FormError<LoginFormValue> name="email" />

            <label>
              <span>Password</span>
              <PasswordToggleInput<LoginFormValue>
                name="password"
                autoComplete={isCreate ? 'new-password' : 'current-password'}
                placeholder="Password"
                revealPassword={revealPassword}
                onToggleReveal={setRevealPassword}
                disabled={isLoading}
              />
            </label>
            <FormError<LoginFormValue> name="password" />

            {isCreate && (
              <>
                <label>
                  <span>Confirm Password</span>
                  <PasswordToggleInput<LoginFormValue>
                    name="confirmPassword"
                    autoComplete="confirm-password"
                    placeholder="Confirm Password"
                    revealPassword={revealPassword}
                    onToggleReveal={setRevealPassword}
                    disabled={isLoading}
                  />
                </label>
                <FormError<LoginFormValue> name="confirmPassword" />
              </>
            )}

            <div className={styles.formActions}>
              {!isCreate && (
                <LinkButton
                  color="secondary-light"
                  size="small"
                  to={RoutingUtils.routes.forgotPassword()}
                >
                  Forgot Password
                </LinkButton>
              )}

              <Button type="submit" color="secondary" className="expanded" disabled={isLoading}>
                <ActivityIndicator isLoading={isLoading && lastProvider === 'email'}>
                  Submit
                </ActivityIndicator>
              </Button>
            </div>
          </>
        )}

        {(showFacebook || showGoogle) && (
          <fieldset className={styles.socialSignin}>
            {showEmail && <legend className="label">or sign {isCreate ? 'up' : 'in'} with</legend>}

            {showGoogle && (
              <Button
                color="primary"
                variant="hollow"
                onClick={handleGoogleClick}
                disabled={isLoading}
              >
                <Icon name="logo-google" />
                <span>Google</span>
              </Button>
            )}

            {showFacebook && (
              <Button
                color="primary"
                variant="hollow"
                onClick={handleFacebookClick}
                disabled={isLoading}
              >
                <ActivityIndicator isLoading={isLoading && lastProvider === 'facebook'}>
                  <Icon name="logo-facebook" />
                  <span>Facebook</span>
                </ActivityIndicator>
              </Button>
            )}
          </fieldset>
        )}

        <div className={styles.disclaimer}>
          You agree to the Stur{' '}
          <Link to={RoutingUtils.routes.terms()}>Terms &amp;&nbsp;Conditions</Link> &amp;{' '}
          <Link to={RoutingUtils.routes.privacyPolicy()}>Privacy&nbsp;Policy</Link>
        </div>
      </Form>
    </Formik>
  );
};
