import classNames from 'classnames';
import { FormikValues } from 'formik';
import React, { ReactElement } from 'react';

import { Button } from '@stur/components/core/button';
import { FormField, FormFieldProps } from '@stur/components/core/form-field';
import { Icon } from '@stur/components/core/icon';

import styles from './password-toggle-input.module.scss';

export type PasswordToggleInputProps<T extends FormikValues> = FormFieldProps<T> & {
  revealPassword: boolean;
  onToggleReveal: (reveal: boolean) => void;
};

export const PasswordToggleInput = <T extends FormikValues>(
  props: PasswordToggleInputProps<T>
): ReactElement => {
  const { type, className, revealPassword, onToggleReveal, ...others } = props;

  const handleButtonClick = () => onToggleReveal(!revealPassword);

  return (
    <span className="input-group">
      <FormField<T>
        type={revealPassword ? 'text' : 'password'}
        className={classNames('input-group-field', className)}
        {...others}
      />
      <span className="input-group-label">
        <Button
          variant="clear"
          className={styles.toggleButton}
          onClick={handleButtonClick}
          aria-label={revealPassword ? 'Hide Password' : 'Show Password'}
        >
          <Icon name={revealPassword ? 'eye-off' : 'eye'} size={20} />
        </Button>
      </span>
    </span>
  );
};
